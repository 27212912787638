import React from "react";
import Layout from "../layouts";
import Search from "../modules/search";
import { Helmet } from "react-helmet";

const Index = () => {
  return (
    <Layout className="search">
      {/* Dont index search page */}
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className="p-search-title-container container">
        <h1 className="p-search-title">Search results</h1>
      </div>
      <Search />
    </Layout>
  );
};

export default Index;
